






import Vue from "vue";
import Detail from "./Detail.vue";

export default Vue.extend({
  components: {
    Detail,
  },
});
